import { initializeApp } from 'firebase/app';
import { StrictMode } from 'react';
import { render } from 'react-dom';
import { App } from './App';

{
    const firebaseConfig = {
        apiKey: 'AIzaSyD0fu6lUuPAGK8JtPin5bAEW8jhcegEKtQ',
        authDomain: 'aotb-kiosk.firebaseapp.com',
        projectId: 'aotb-kiosk',
        appId: '1:360554978065:web:e7eb4bd2933d960ef10ab0',
    };
    initializeApp(firebaseConfig);
}

render(
    <StrictMode>
        <App />
    </StrictMode>,
    document.getElementById('app')
);
