import { Navigate, Route, Routes } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import { lazy, Suspense } from 'react';
import type { User } from 'firebase/auth';
import { LoginPage } from './sections/LoginPage';

const CRM = lazy(async () => ({
    default: (await import('./sections/CRM')).CRM,
}));

interface AppRoutesProps {
    readonly user: User | null;
}

function AppRoutes({ user }: AppRoutesProps): JSX.Element {
    return (
        <Suspense fallback={<LinearProgress />}>
            <Routes>
                <Route
                    path="/crm/*"
                    element={user ? <CRM /> : <Navigate to="/login" />}
                />
                <Route
                    path="/login"
                    element={user ? <Navigate to="/crm" /> : <LoginPage />}
                />
                <Route path="*" element={<Navigate to={user ? '/crm' : '/login'} />} />
            </Routes>
        </Suspense>
    );
}

export { AppRoutes };
