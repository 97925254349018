import {
    AppBar,
    Button,
    Container,
    LinearProgress,
    Toolbar,
    Typography,
} from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { getApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import type { User } from 'firebase/auth';
import { BrowserRouter } from 'react-router-dom';
import { AppRoutes } from './AppRoutes';

function App(): Readonly<JSX.Element> {
    const [user, setUser] = useState<User | null | undefined>(undefined);

    const auth = useMemo(() => getAuth(getApp()), [getApp, getAuth]);

    onAuthStateChanged(auth, setUser);

    const logout = useCallback(async () => signOut(auth), [signOut, auth]);

    return (
        <>
            <AppBar position="sticky">
                <Toolbar>
                    <Typography
                        variant="h6"
                        color="inherit"
                        component="div"
                        sx={{ flexGrow: 1 }}
                    >
                        iTouch Network CRM
                    </Typography>
                    {user ? (
                        <Button variant="contained" onClick={logout}>
                            Logout
                        </Button>
                    ) : null}
                </Toolbar>
            </AppBar>
            <Container id="main">
                <BrowserRouter>
                    {user !== undefined ? (
                        <AppRoutes user={user} />
                    ) : (
                        <LinearProgress />
                    )}
                </BrowserRouter>
            </Container>
        </>
    );
}

export { App };
