import { Button, Stack, TextField, Typography } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import type { ChangeEventHandler } from 'react';
import { getApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

function LoginPage(): JSX.Element {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const auth = useMemo(() => getAuth(getApp()), [getApp, getAuth]);

    const emailChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
        (e) => {
            setEmail(e.target.value);
        },
        [setEmail]
    );

    const passwordChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        // eslint-disable-next-line @typescript-eslint/prefer-readonly-parameter-types
        (e) => {
            setPassword(e.target.value);
        },
        [setPassword]
    );

    const login = useCallback(
        async () => signInWithEmailAndPassword(auth, email, password),
        [auth, email, password]
    );

    return (
        <Stack spacing={2} id="login">
            <Typography align="center" variant="h3">
                Sign In
            </Typography>
            <TextField
                type="email"
                variant="outlined"
                value={email}
                onChange={emailChange}
            />
            <TextField
                type="password"
                variant="outlined"
                value={password}
                onChange={passwordChange}
            />
            <Button onClick={login}>Sign In</Button>
        </Stack>
    );
}

export { LoginPage };
